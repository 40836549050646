import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import { Card, Col, ListGroupItem, Row, Spinner } from "react-bootstrap";
import CardHeader from "../../components/common/CardHeader";
import LinkButton from "../../components/common/LinkButton";
import { ItemShow } from "../../components/form";
import toast from "react-hot-toast";

const FlightDestinationShow = () => {
      const { destinationId } = useParams();
      const [airline, setAirline] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/flight_destinations/${destinationId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setAirline(response.data.destination);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Flight Destination Detail">
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/flight_destinations"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg={5}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Name"
                                                                        value={
                                                                              airline?.name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Code"
                                                                        value={
                                                                              airline?.code
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="City"
                                                                        value={
                                                                              airline?.city
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="City Code"
                                                                        value={
                                                                              airline?.city_code
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Country"
                                                                        value={
                                                                              airline?.country
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Country Code"
                                                                        value={
                                                                              airline?.country_code
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Featured"
                                                                        value={`${
                                                                              airline.featured_status
                                                                                    ? "Active"
                                                                                    : "Disable"
                                                                        }`}
                                                                  />
                                                                  <ItemShow
                                                                        title="Date"
                                                                        value={
                                                                              airline.date
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Status"
                                                                        value={`${
                                                                              airline.status
                                                                                    ? "Active"
                                                                                    : "Disable"
                                                                        }`}
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <div className="text-center">
                                                      <Spinner />
                                                </div>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default FlightDestinationShow;
