import React, { useState } from "react";
import axios from "axios";
import $ from "jquery";
import { toast } from "react-hot-toast";

import { Card, Col, Form, Row } from "react-bootstrap";
import CardHeader from "../../components/common/CardHeader";
import LinkButton from "../../components/common/LinkButton";
import FormInput from "../../components/form/FormInput";
import { FormButton, FormImage, FormStatusSelect } from "../../components/form";

const FlightDestinationCreate = () => {
      const [name, setName] = useState("");
      const [code, setCode] = useState("");
      const [city, setCity] = useState("");
      const [cityCode, setCityCode] = useState("");
      const [country, setCountry] = useState("");
      const [countryCode, setCountryCode] = useState("");
      const [featuredStatus, setFeaturedStatus] = useState("");
      const [status, setStatus] = useState("");
      const [loading, setLoading] = useState(false);

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  name: name,
                  code: code,
                  city: city,
                  city_code: cityCode,
                  country: country,
                  country_code: countryCode,
                  status: status,
                  featured_status: featuredStatus,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/flight_destinations`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              $("form").trigger("reset");
                              toast.success(response.data.message);
                        }

                        if (response.data.result === "error") {
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.city) {
                                    toast.error(response.data.message.city);
                              }
                              if (response.data.message.city_code) {
                                    toast.error(
                                          response.data.message.city_code
                                    );
                              }
                              if (response.data.message.code) {
                                    toast.error(response.data.message.code);
                              }
                              if (response.data.message.country) {
                                    toast.error(response.data.message.country);
                              }
                              if (response.data.message.country_code) {
                                    toast.error(
                                          response.data.message.country_code
                                    );
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.featured_status) {
                                    toast.error(
                                          response.data.message.featured_status
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Create Flight Destination">
                                                <LinkButton
                                                      title="Back"
                                                      link="/dashboard/flight_destinations"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Name"
                                                            classes={8}
                                                            required={true}
                                                            handleChange={
                                                                  setName
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Code"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setCode
                                                            }
                                                      />
                                                      <FormInput
                                                            title="City"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setCity
                                                            }
                                                      />
                                                      <FormInput
                                                            title="City Code"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setCityCode
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Country"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setCountry
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Country Code"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setCountryCode
                                                            }
                                                      />

                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            title="Featured Status"
                                                            classes={4}
                                                            setStatus={
                                                                  setFeaturedStatus
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default FlightDestinationCreate;
