import { Table } from "react-bootstrap";
import styled from "styled-components";

export const TableBody = styled(Table)`
      font-size: 14px;
`;

export const TableBorderLessBody = styled.table`
      width: 100%;
      font-size: 14px;
      border-left: 1px solid #dee2e6 !important;
      border-right: 1px solid #dee2e6 !important;
      border-bottom: 1px solid #dee2e6 !important;
      margin-bottom: 5px;

      thead {
            width: 100%;

            tr {
                  background-color: ${(props) => props.theme.primaryColor};
            }

            th {
                  color: ${(props) => props.theme.white};
                  background-color: transparent;
                  font-weight: 500;
                  font-size: 14px;
                  text-transform: uppercase;
                  /* font-size: 11px; */
                  height: 40px;
                  padding: 2px 8px;
                  font-weight: normal;
                  border-right: 1px solid rgba(255, 255, 255, 0.1);
                  text-align: center;
            }
      }

      tbody {
            width: 100%;

            tr {
                  background-color: #f9f9f9;

                  &:nth-child(4n + 1) {
                        background: #fff;
                  }

                  &:nth-child(4n + 2) {
                        background: #fff;
                  }

                  td {
                        border-bottom: none !important;
                        padding: 4px 8px;
                        font-size: 14px;
                        border-right: 0 !important;
                        line-height: 19px;
                        text-align: center;

                        &.list-td {
                              position: relative;
                              border-bottom: 1px solid #dee2e6 !important;
                              padding: 3px 8px;
                              text-align: left;

                              p {
                                    margin: 0;
                                    color: #f4a934;
                                    font-size: 13px;
                              }
                        }
                  }
            }
      }
`;

export const TableBorderBody = styled.table`
      width: 100%;
      font-size: 14px;
      border-left: 1px solid #dee2e6 !important;
      border-right: 1px solid #dee2e6 !important;
      border-bottom: 1px solid #dee2e6 !important;
      margin-bottom: 5px;

      thead {
            width: 100%;

            tr {
                  background-color: ${(props) => props.theme.primaryColor};
            }

            th {
                  color: ${(props) => props.theme.white};
                  background-color: transparent;
                  font-weight: 500;
                  font-size: 14px;
                  text-transform: uppercase;
                  /* font-size: 11px; */
                  height: 40px;
                  padding: 2px 8px;
                  font-weight: normal;
                  border-right: 1px solid rgba(255, 255, 255, 0.1);
                  text-align: center;
            }
      }

      tbody {
            width: 100%;

            tr {
                  background-color: #f9f9f9;

                  &:nth-child(4n + 1) {
                        background: #fff;
                  }

                  td {
                        border-bottom: none !important;
                        padding: 4px 8px;
                        font-size: 14px;
                        border-right: 0 !important;
                        line-height: 19px;
                        text-align: center;

                        &.list-td {
                              position: relative;
                              border-bottom: 1px solid #dee2e6 !important;
                              padding: 3px 8px;
                              text-align: left;

                              p {
                                    margin: 0;
                                    color: #f4a934;
                                    font-size: 13px;
                              }
                        }
                  }
            }
      }
`;
