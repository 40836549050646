import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";

import { FormButton, FormInput, FormStatusSelect } from "../../components/form";
import { Card, Col, Form, Row } from "react-bootstrap";
import CardHeader from "../../components/common/CardHeader";
import LinkButton from "../../components/common/LinkButton";

const FlightDestinationEdit = () => {
      const { destinationId } = useParams();

      const [name, setName] = useState("");
      const [code, setCode] = useState("");
      const [city, setCity] = useState("");
      const [cityCode, setCityCode] = useState("");
      const [country, setCountry] = useState("");
      const [countryCode, setCountryCode] = useState("");
      const [status, setStatus] = useState("");
      const [featuredStatus, setFeaturedStatus] = useState("");
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/flight_destinations/${destinationId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setName(response.data.destination.name);
                              setCode(response.data.destination.code);
                              setCity(response.data.destination.city);
                              setCityCode(response.data.destination.city_code);
                              setCountry(response.data.destination.country);
                              setCountryCode(
                                    response.data.destination.country_code
                              );
                              setFeaturedStatus(
                                    response.data.destination.featured_status
                              );
                              setStatus(response.data.destination.status);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  name: name,
                  code: code,
                  city: city,
                  city_code: cityCode,
                  country: country,
                  country_code: countryCode,
                  featured_status: featuredStatus,
                  status: status,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/flight_destinations/${destinationId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.city) {
                                    toast.error(response.data.message.city);
                              }
                              if (response.data.message.city_code) {
                                    toast.error(
                                          response.data.message.city_code
                                    );
                              }
                              if (response.data.message.country_code) {
                                    toast.error(
                                          response.data.message.country_code
                                    );
                              }
                              if (response.data.message.country) {
                                    toast.error(response.data.message.country);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.featured_status) {
                                    toast.error(
                                          response.data.message.featured_status
                                    );
                              }
                              if (response.data.message.code) {
                                    toast.error(response.data.message.code);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Edit Flight Destination">
                                                <LinkButton
                                                      link="/dashboard/flight_destinations"
                                                      title="Back"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="row g-3">
                                                      <FormInput
                                                            title="Name"
                                                            required={true}
                                                            classes={7}
                                                            value={name}
                                                            handleChange={
                                                                  setName
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Code"
                                                            classes={5}
                                                            required={true}
                                                            handleChange={
                                                                  setCode
                                                            }
                                                            value={code}
                                                      />
                                                      <FormInput
                                                            title="City"
                                                            classes={5}
                                                            required={true}
                                                            handleChange={
                                                                  setCity
                                                            }
                                                            value={city}
                                                      />
                                                      <FormInput
                                                            title="City Code"
                                                            classes={5}
                                                            required={true}
                                                            handleChange={
                                                                  setCityCode
                                                            }
                                                            value={cityCode}
                                                      />
                                                      <FormInput
                                                            title="Country"
                                                            classes={5}
                                                            required={true}
                                                            handleChange={
                                                                  setCountry
                                                            }
                                                            value={code}
                                                      />
                                                      <FormInput
                                                            title="Country Code"
                                                            classes={5}
                                                            required={true}
                                                            handleChange={
                                                                  setCountryCode
                                                            }
                                                            value={countryCode}
                                                      />
                                                      <FormStatusSelect
                                                            title="Featured Status"
                                                            selected={
                                                                  featuredStatus
                                                            }
                                                            classes={4}
                                                            setStatus={
                                                                  setFeaturedStatus
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            title="Status"
                                                            selected={status}
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default FlightDestinationEdit;
